import { useEffect, useState } from "react";
import { useSession, LogoutReason } from "../Auth/hooks/useSession";
import AuthLogoutMessage from "../Auth/components/AuthLogoutMessage";
import { getFetchOptions } from "../../utils/fetch";
import useFetch from "../../hooks/useFetch";
import useFetchAuto from "../../hooks/useFetchAuto";
import LoadingSpinner from "../../components/loadingSpinner/LoadingSpinner";
import Modal from "../../components/modals/Modal";
import MessageModal, { MessageModalType } from "../../components/modals/MessageModal";
import Button, { ButtonType } from "../../components/Button";
import Row from "../../components/Row";
import DataTable from "../../components/DataTable";
import { formatDate } from "../../utils/formatting";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

const ViewCustomer = (props) => {
	const { reason } = useSession();
	const [deleting, setDeleting] = useState(false);
	const [resending, setResending] = useState(false);
	const [users, setUsers] = useState([]);
	const [confirmDelete, setConfirmDelete] = useState(false);
	const [confirmResend, setConfirmResend] = useState(false);
	const { loading, data } = useFetchAuto("/api/CustomerById/" + props.item.Id);
	const { ready, execute, executingFetch } = useFetch();
	const [everLoggedIn, setEverLoggedIn] = useState(false);
	const [fatalError, setFatalError] = useState(false);


	// Perform REST request.
	useEffect(() => {
		if (ready && deleting) {
			props.setFetchParentData(false);
			const options = getFetchOptions({
				method: "DELETE"
			});
			execute("/api/Customer/" + props.item.Id, options, (error) => {
				setDeleting(false);
				if (error) {
					console.error(error.message);
				}
				props.setFetchParentData(true);
				props.setModal(false);
			});
		}
	}, [ready, props.item, deleting, execute]);

	useEffect(() => {
		if (ready && data) {
			const options = getFetchOptions({
				method: "GET"
			});
			execute(`api/CustomerUsers/${data.CompanyId}`, options, (error, usersArray) => {
				if (error) {
					console.error(error.message);
				}
				else {
					setUsers(usersArray || { users: [] });
				}
			})
		}
	}, [data, ready, execute]);

	useEffect(() => {
		if (ready && resending) {
			const resendContactEmail = data.ContactEmail;
			const options = getFetchOptions({
				method: "POST",
				body: resendContactEmail
			});
			execute("/api/Provisioning/ResendRegistrationEmail", options, (error, data) => {
				setResending(false);
				if (error) {
					setFatalError(true);
					console.error(error.message);
				}
				props.setFetchParentData(true);
				props.setModal(false);
			});
		}
	}, [data, props.item, ready, resending, execute]);

	function onClickClose(modal) {
		props.setFetchParentData(true);
		props.setModal(modal);
	}

	function onClickDelete() {
		setConfirmDelete(true);
	}

	function onClickResend() {
		setConfirmResend(true);
	}

	function onClickConfirmResend(yes) {
		if (yes) {
			setResending(true);
		}
		setConfirmResend(false);
	}

	function onClickConfirmDelete(yes) {
		if (yes) {
			setDeleting(true);
		}
		setConfirmDelete(false);
	}

	const handleCloseModal = () => {
		setResending(false);
		window.location.reload();
	}

	const getPackageInformation = () => {
		if (data) {
			let packageDetails = data.PackageName;
			if (data.LevelString.length) {
				packageDetails += " (" + data.LevelString + ")";
			}
			if (data.SeatsString.length) {
				return <>{packageDetails}<br />{data.SeatsString} Seats ({(data.PUP) ? "PUP" : "PCP"})</>;
			}
			else {
				return packageDetails;
			}
		}
		return "";
	};

	const handleDate = (dateString) => {
		if (!dateString) {
			setEverLoggedIn(false);
			return "Not logged in";
		}
		const date = new Date(dateString);
		if (isNaN(date)) {
			setEverLoggedIn(false);
			return "Not logged in";
		}
		setEverLoggedIn(true);
		return formatDate(dateString);
	};

	const columns = [
		{
			field: "FirstName",
			headerName: "First Name",
		},
		{
			field: "LastName",
			headerName: "Last Name",
		},
		{
			field: "Username",
			headerName: "Username",
		},
		{
			field: "LastLoginDate",
			headerName: "Last Login",
			valueGetter: (rowData) => handleDate(rowData.LastLoginDate),
		}
	];


	const ViewContent = (
		<>
			{loading
				? <LoadingSpinner active={loading} />
				: data &&
					<div>
						<Row class="my-4">
							<div className="ap-col col-2">
								<h4>Details</h4>
							</div>
							<div className="ap-col "><p className="mt-0">{data.Name}</p></div>
						</Row>
						<Row class="mb-4">
						<div className="ap-col col-2">
								<h4>Package</h4>
							</div>
							<div className="ap-col"><p className="mt-0">{getPackageInformation()}</p></div>
						</Row>
						<DataTable columns={columns} rows={users} columnIdName="Id" className="p-datatable-sm" />
					</div>
			}
		</>
	);

	const buttons = <>
		{data ? (
			<Button type={ButtonType.Tertiary} label={`Delete ${data.Name}`} onClick={onClickDelete} />
		) : (
			<Button type={ButtonType.Tertiary} label={"Delete"} onClick={onClickDelete} />
		)}
		{!everLoggedIn && <Button type={ButtonType.Secondary} icon={faEnvelope} label={"Resend Registration Email"} onClick={onClickResend} /> }
	</>

	const ResendModal = (
		data &&
		<MessageModal title="Confirm Resend" type={MessageModalType.Question} onClick={onClickConfirmResend} size="m">
			Are you sure you want to resend the registration email to {data.Name}?
		</MessageModal>
	);


	const FatalErrorModal = (
		<MessageModal title="An Error Occured" type={MessageModalType.Information} size="m" onClick={handleCloseModal}>
			An error occurred when trying to resend the registration email. Please try again. If the problem persists, please contact ITEL.
		</MessageModal>
	);


	const DeleteModal = (
		data &&
		<MessageModal title="Confirm Customer Deletion" type={MessageModalType.Question} onClick={onClickConfirmDelete} size="m">
			Are you sure you want to delete {data.Name}?
		</MessageModal>
	);

	const ViewModal = (
		<Modal
			title="Customer Details"
			showModal={props.showModal}
			content={ViewContent}
			size="m"
			modalButtons={buttons}
			close={onClickClose}
		/>
	);

	const render = () => {
		if (reason !== LogoutReason.None) {
			return <AuthLogoutMessage reason={reason} />;
		}
		if (executingFetch) {
			return <LoadingSpinner active={executingFetch} />;
		}
		if (confirmResend) {
			return ResendModal;
		}
		if (fatalError) {
			return FatalErrorModal;
		}
		if (confirmDelete) {
			return (DeleteModal);
		}
		return (ViewModal);
	}

	return (
		<>{render()}</>
	);
};

export default ViewCustomer;