import { faEnvelope, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkbox } from "primereact/checkbox";
import { useEffect, useState } from "react";
import Alert, { AlertType } from "../../../components/Alert";
import DataTable from "../../../components/DataTable";
import LoadingSpinner from "../../../components/loadingSpinner/LoadingSpinner";
import MessageModal, { MessageModalType } from "../../../components/modals/MessageModal";
import useFetch from "../../../hooks/useFetch";
import { getFetchOptions } from "../../../utils/fetch";
import { objectsEqual } from "../../../utils/validation";
import AuthLogoutMessage from "../../../features/Auth/components/AuthLogoutMessage";
import { LogoutReason, useSession } from "../../../features/Auth/hooks/useSession";
import { getAdditionalOffersText, getPackageLevelJsx } from "../utilities";
import PackageEditorSection from "./PackageEditorSection";
import PackageSelectionSection from "./PackageSelectionSection";

const ProvisionCompany = ({ editing, packagesToAdd, setPackagesToAdd, setAddPackageValid, showSelectedPackages, setShowSelectedPackages, customer, offerRules,
	marketingConsent, setMarketingConsent, users, setUsers, setSubmitEnabled, addPackageValid }) => {
		
	const { reason } = useSession();
  const [selectedPackage, setSelectedPackage] = useState("");
  const [selectedPackageRules, setSelectedPackageRules] = useState({});
  const { ready, execute, executingFetch } = useFetch();
  const [loadedUsers, setLoadedUsers] = useState([]);
  const [selectedPackageObj, setSelectedPackageObj] = useState({
    packageId: -1,
    packageName: '',
    seats: 0,
		seatBlockSize: 0,
		guruByUser: false,
    packageLevel: -1,
    additionalOffers: [],
  });
  const [fatalError, setFatalError] = useState(false);
	const [dropdownOptions, setDropdownOptions] = useState([]);
	const [resendConfirmationUser, setResendConfirmationUser] = useState("");
	const [originalData, setOriginalData] = useState(null);

  // Fetch data effect
  useEffect(() => {
    if (ready && customer) {
      const options = getFetchOptions({
        method: "GET"
			});
			const crmContactsOnly = !editing;
      execute("/api/Provisioning/Contacts/" + customer.accountid + "/" + crmContactsOnly, options, (error, data) => {
        if (error) {
          setFatalError(true);
          console.error(error.message);
				}
				else {
          setFatalError(false);
					setLoadedUsers(data || { Users: [] });
					if (editing) {
						const selectedUsers = data.filter(x => x.Selected);
						setUsers(selectedUsers);
						setOriginalData({
							users: selectedUsers,
							packages: packagesToAdd,
							consent: marketingConsent
						});
					}
        }
      });
    }
  }, [customer, ready, execute, editing]);

  // Update add package validity effect
  useEffect(() => {
    const isValid =
      selectedPackageObj.packageName !== '' &&
      selectedPackageObj.seats > 0 &&
      ((!selectedPackageRules.Levels.length) || (selectedPackageObj.packageLevel !== -1));

    setAddPackageValid(isValid);
  }, [selectedPackageObj, selectedPackageRules.Levels, setAddPackageValid]);

  // Handle selected package function
  const handleSelectedPackage = (value) => {
    const selectedPackageRules = offerRules.find(rule => rule.Id === value);
    setSelectedPackageRules(selectedPackageRules);
		setSelectedPackageObj(prevState => ({
			...prevState,
			packageId: value,
			packageName: selectedPackageRules.Name,
			seatBlockSize: selectedPackageRules.SeatBlockSize,
			guruByUser: selectedPackageRules.GuruByUser
		}));
    setSelectedPackage(value);
    if (selectedPackageRules.PreventMarketingConsent) {
      setMarketingConsent(false);
    }
		setShowSelectedPackages(false);
  };
	
  // Handle user admin toggle function
  const handleUserAdminToggle = (index, checked) => {
    const userToToggle = loadedUsers[index];
    const userExists = users.some(user => user.Username === userToToggle.Username);

    if (checked && !userExists) {
			if (editing) {
				userToToggle.Selected = userToToggle.InitiallySelected;
			}
      const updatedUsers = [...users, userToToggle];
			setUsers(updatedUsers);
		}
		else if (!checked && userExists) {
			if (editing) {
				userToToggle.InitiallySelected = userToToggle.Selected;
				userToToggle.Selected = false;
			}
			let updatedUser = users.filter(user => user.Username !== userToToggle.Username);
      setUsers(updatedUser);
    }
  };

	const resetSelectedPackageObj = () => {
		setSelectedPackageObj({
			packageId: -1,
			packageName: '',
			seats: 0,
			seatBlockSize: 0,
			guruByUser: false,
			packageLevel: -1,
			additionalOffers: [],
		})
	}

	const handleAddPackage = () => {
		setPackagesToAdd(currentPackages => [...currentPackages, selectedPackageObj]);
		resetSelectedPackageObj();
		setShowSelectedPackages(true);
		setSelectedPackage(null);
	}

	// Submit validation
	useEffect(() => {
		let enabled = (!selectedPackage && (users.length > 0 && packagesToAdd.length > 0));
		if (!editing) {
			setSubmitEnabled(enabled);
		}
		else {
			if (originalData && enabled) {
				enabled = (
					originalData.users.length !== users.length ||
					!originalData.users.every((o, idx) => objectsEqual(o, users[idx])) ||
					originalData.packages.length !== packagesToAdd.length ||
					!originalData.packages.every((o, idx) => objectsEqual(o, packagesToAdd[idx])) ||
					originalData.consent !== marketingConsent
				);
			}
			setSubmitEnabled(enabled);
		}
	}, [editing, selectedPackage, users, packagesToAdd, marketingConsent, originalData, setSubmitEnabled]);
	
	const handleResendEmail = (rowData) => {
		if (ready) {
			const options = getFetchOptions({
				method: "POST",
				body: rowData.Username
			});
			execute("/api/Provisioning/ResendRegistrationEmail/", options, (error, data) => {
				if (error) {
					setFatalError(true);
					console.error(error.message);
				}
				else {
					setResendConfirmationUser(rowData.Username);
				}
			});
		}
	};

	const handleMarketingConsentChange = (event) => {
		setMarketingConsent(event.checked);
	};

	const handleDeletePackage = (indexToRemove) => {
		const newPackages = packagesToAdd.filter((_, index) => index !== indexToRemove);
		setPackagesToAdd(newPackages);
		if (newPackages.length < 1) {
			setSelectedPackage("");
			setShowSelectedPackages(false);
		}
	};

	const handleCancel = () => {
		setSelectedPackage("");
		setShowSelectedPackages(Boolean(packagesToAdd.length));
	};

	const handleResendConfirmation = () => {
		setResendConfirmationUser("");
	};

  useEffect(() => {
    const updatedOptions = offerRules.filter(option =>
      !packagesToAdd.some(packageItem => packageItem.packageId === option.Id)
    );
		setDropdownOptions(updatedOptions);
	}, [packagesToAdd, offerRules]);
	
	const ResendModal = (
		<MessageModal title="Confirmation" type={MessageModalType.Information} size="m" onClick={handleResendConfirmation}>
			The registration email has been resent to '{resendConfirmationUser}'.
		</MessageModal>
	);

  // Admin checkbox template
	const adminCheckboxTemplate = (rowData, options) => {
		if (!rowData.FirstName || !rowData.Username) {
			return <span>Incomplete Details</span>;
		}
		if (rowData.RegisteredElsewhere) {
			return <span>Exists in another Company</span>;
		}

		const isUserChecked = rowData.Selected || users.some(user => user.Username === loadedUsers[options.rowIndex].Username);
		return (
			<Checkbox
				key={`admin-checkbox-${rowData.Username}`} // Use Username for key
				checked={isUserChecked}
				onChange={(e) => handleUserAdminToggle(options.rowIndex, e.checked)}
			/>
		);
	};

	const resendEmailTemplate = (rowData) => {
		return (
			<>
				{(!rowData.Registered && rowData.Selected) && (
					<button key={`resend-${rowData.Username}`} className="datatable-action-button" onClick={() => handleResendEmail(rowData)}>
						<FontAwesomeIcon icon={faEnvelope} />
					</button>
				)}
			</>
		);
	};

	const getUserColumns = () => {
		const result = [
			{
				headerName: "Name",
				valueGetter: (rowData) => `${rowData.FirstName} ${rowData.LastName}`,
				style: { width: '40%' },
				sortable: false
			},
			{
				field: "Username",
				headerName: "Email",
				style: { width: '60%' }
			},
			{
				field: "CreationDate",
				headerName: "Register",
				class: "header-center",
				valueGetter: adminCheckboxTemplate,
				style: { width: '150px', textAlign: 'center' },
				sortable: false
			},
		];
		if (editing) {
			result.push({
				headerName: "Resend Registration Email",
				class: "header-center",
				valueGetter: resendEmailTemplate,
				style: { width: '50px', textAlign: 'center' },
				sortable: false
			});
		}
		return result;
	}

	const PackageListSection = (
		<>
			{showSelectedPackages && (
				<div>
					<h4 className="title-top-margin">Packages</h4>
					<div className="list-table-header">
						<p>Package</p>
						<p>Seats</p>
					</div>
					{packagesToAdd.map((item, packageIndex) => (
						<div className="list-table-item" key={packageIndex}>
							<div className="p-with-div-nesting font_s">
								{item.packageName}
								{getPackageLevelJsx(offerRules, item.additionalOffers && item.additionalOffers.length, item.packageId, item.packageLevel)}
								{getAdditionalOffersText(item.additionalOffers)}
							</div>
							<p>{item.seats} x {item.seatBlockSize}</p>
							<button onClick={() => handleDeletePackage(packageIndex)} style={{ backgroundColor: 'none' }}><FontAwesomeIcon icon={faTrashCan} /></button>
						</div>
					))}
				</div>
			)}
		</>
	);

	const MarketingConsentSection = (
		<>
			<h4 className="title-top-margin table-title">Marketing Consent</h4>
			<div className="title-top-margin">
				<div>
					<Checkbox checked={marketingConsent} onChange={handleMarketingConsentChange} />
					<label style={{ marginLeft: '10px' }}>Enable marketing consent</label>
				</div>
			</div>
		</>
	);

	const userExistsRowClass = (rowData) => {
		return { "red": !!rowData.RegisteredElsewhere }; // Why do I need a double exclamation to force it to a boolean?
	};

	const UsersSection = (
		<>
			<h4 className="title-top-margin table-title">Users</h4>
			<p>A customer needs at least one selected user to be provisioned.</p>
			{fatalError && <Alert type={AlertType.Error}>Error Fetching Users: Please try again. If the problem persists, please contact the development department.</Alert>}
			{!fatalError &&
				<DataTable columns={getUserColumns()} rows={loadedUsers} rowsPerPage={5} responsiveLayout="scroll" rowClassName={userExistsRowClass} />
			}
		</>
	);
	
	const render = () => {
		if (reason !== LogoutReason.None) {
			return <AuthLogoutMessage reason={reason} />;
		}
		if (executingFetch) {
			return <LoadingSpinner active={executingFetch} />;
		}
		if (resendConfirmationUser.length) {
			return ResendModal;
		}
		return (
		<>
			<PackageSelectionSection
				offerRules={offerRules}
				showSelectedPackages={showSelectedPackages}
				dropdownOptions={dropdownOptions}
				selectedPackage={selectedPackage}
				handleSelectedPackage={handleSelectedPackage}
			/>
			<PackageEditorSection
				selectedPackage={selectedPackage}
				selectedPackageObj={selectedPackageObj}
				setSelectedPackageObj={setSelectedPackageObj}
				selectedPackageRules={selectedPackageRules}
				handleCancel={handleCancel}
				handleAddPackage={handleAddPackage}
				addPackageValid={addPackageValid}
			/>
			{PackageListSection}
			{MarketingConsentSection}
			{UsersSection}
		</>);
	}

	return (
		<>{render()}</>
	);
};

export default ProvisionCompany;
